// -----------------------------------//
// Variables
// -----------------------------------//

// gold #11165A
// gold dark #11165A
// green #2A319B
// green dark #2A319B

// -----------------------------------//
// Sup Nav colours
// -----------------------------------//
$sup-menu-bg: #3A50A5;
$sup-menu-highlight: #11165A;
$sup-menu-colour: #fff;

// -----------------------------------//
// Nav Menu colours
// -----------------------------------//
$menu-background-bg: #2A319B;
$menu-text-colour: #fff;
$sub-menu-bg: #2A319B;

// Hovers
$menu-hover: #3A50A5;
$sub-menu-hover: #3A50A5;

//MEANMENU
$mm-reveal-background: #2A319B;
$mm-navigation-background: #3A50A5;

// -----------------------------------//
// HEADER LOGO SECTION
// -----------------------------------//
$header-background: #fff;
$logo-colour: #11165A;

// -----------------------------------//
// NEWS DIARY SECTION
// -----------------------------------//

$scroll-hvr: #11165A; //twitter scrollbar hover
$date-bg: #3A50A5; // diary date bg
$date-box-bg: #11165A; // diary entry bg

// buttons
$btn-bg: #fff;
$btn-hvr: #11165A;

// -----------------------------------//
// FOOTER colours
// -----------------------------------//
$footer-bg: #fff;
$footer-colour: #11165A;
$footer-link: #11165A;
$footer-link-hvr: #2A319B;
$footer-register: #2A319B;

//subfooter
$subfooter-bg: #11165A;
$subfooter-link: #fff;
$subfooter-link-hvr: #fff;


// -----------------------------------//
// INSIDE PAGES
// -----------------------------------//
$link-color: deepskyblue;
$h1-color: #11165A;
$h2-color: #2A319B;
$h3-bg-color:#2A319B;
$h4-bg-color:#11165A;

//Related pages
$related-pages-h2:#fff;
$related-pages-bg: #2A319B;
$related-pages-hvr:#11165A;


//News
$little-date-line: #11165A;

//Subscribe
$subscribe-bg-colour: #11165A;

//Diary list inside page colours
$diary-date-bg: #2A319B;
$add-to-calendar-bg: #11165A;

//Gallery
$gallery-bg: #2A319B;

//Photo box text colours (DARK background)
// Date
$box-light-date: #dbed2b;

//Photo box text colours (LIGHT background)
// Date
$box-dark-date: #2A319B;

//Grid and List view styles
h2.ListingHead {
	background: #2A319B;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: #2A319B;
$search-highlight-color: #fff;

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//
$table-highlight-color: #fff;
$table-highlight-background-color: #2A319B;
$table-border-color: #2A319B;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//
$blockquote-background-color: #fff;
$blockquote-color: #2A319B;
$blockquote-speech-mark-color: #11165A;
$blockquote-border-color: #11165A;
// $link-color: hotpink;
// $h1-color: hotpink;
// $h2-color:hotpink;
// $h3-bg-color:hotpink;
// $h4-bg-color:hotpink;

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// BODY
body {
	color: #444;
	font-size: 18px;
	font-family: 'Jost', sans-serif;
	text-align: center;
	line-height: 1.5;
	padding: 0px;
	margin: 0;
	position: relative;
	overflow-x: hidden;
}

// LINKS
body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

// a img:hover	{opacity: 0.8}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 48px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
	border-radius: 10px
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning {
	width: 100%;
	background: #b20000;
	color: #fff;
	overflow: hidden;
	padding: 0 0 10px 0;
	transition: .5s;
	display: block
}

#ie-warning p {
	margin: 5px auto;
}

#ie-warning a {
	color: #fff;
	text-decoration: underline;
	cursor: pointer;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

	// Put your IE10+-specific CSS here
	// IE Truncate
	ul.SideList p.title {
		span {
			display: block;
			max-height: 60px;
		}
	}

	p#ienotsupportedmessage {
		display: block !important;
		max-width: none !important;
		line-height: 24px !important;
	}

}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

.body_6163 .wrapper-inside {
	width: 100%;
	max-width: 100%;
}


// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 16px 0;
	background: $header-background;
	position: relative;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	font-size: 0;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	display: inline-block;
	margin: 0;
	padding: 0;

	a {
		float: left;
		margin: 24px 0 0 0;
		@include font($logo-colour, 58px);
		text-align: center;
		line-height: 1;
		text-transform: uppercase;

		span {
			display: block;
			margin: 0;
			font-size: 30px;
			text-transform: none;
			white-space: nowrap;
		}

		img#logo {
			float: left;
			margin: -24px 28px 0 0;
		}

		&:hover {
			opacity: .6;
		}
	}
}

// TRUST TAB
.partnership-trust {
	width: 310px;
	height: 172px;
	background: #2a5397;
	transition: 1s;
	z-index: 999;
	position: absolute;
	top: 40px;
	right: -180px;

	#trust-tab {
		float: left;
		width: 130px;
		height: 99%;
		background: #e9e9e9 url(/i/design/The-Blessed-Peter-Snow-Catholic-Academy-Trust.png) no-repeat center;
		background-size: 98px 144px;
		cursor: pointer;
		position: relative;
	}

	.arrow {
		width: 10px;
		height: 100%;
		background: #dcdcdc;
		position: relative;

		&:before {
			position: absolute;
			content: '';
			@include abcent;
			left: 2;
			width: 5px;
			height: 6px;
			background: url(/i/design/tab-arrow.png) no-repeat center;
			background-size: contain;
		}
	}

	#trust-text {
		float: right;
		padding: 10px;
		width: calc(100% - 130px);
		height: 100%;
	}

	p {
		margin: 0;
		opacity: 0;
		color: #fff;
		width: 100%;
		line-height: 1.1;
		transition: 1s;
		text-align: right;
		font-size: 13px;
	}

	a {
		clear: right;
		opacity: 0;
		display: inline-block;
		margin: 0;
		text-align: right;
		text-decoration: none;
		color: #bbca2b;
	}

}

.trust-extended {
	transition: 1s;
	right: 0px;

	p,
	a {
		opacity: 1;
		transition: 1s;
	}

	.flip-it {
		transform: rotate(180deg) !important;
	}
}

// SUP NAV
#sup-nav {
	background: $sup-menu-bg;

	.wrapper {
		text-align: left;

		#sup-nav-box {
			display: flex;
			justify-content: space-between;
		}

		p {
			display: inline-block;
			margin: 0;
			padding: 9px 0;
			min-height: 40px;
			@include font ($sup-menu-colour, 14px);

			&:nth-of-type(2) {
				padding: 9px 18px;
				background: $sup-menu-highlight ;
			}
		}

		a[href^="mailto:"] {
			display: inline-block;
			margin: 0;
			padding: 9px 0;
			@include font ($sup-menu-colour, 14px);

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

// -----------------------------------//
// TOOLS DOCK
// -----------------------------------//

#tools-dock {
	float: right;
	padding: 9px 18px;
	background: $sup-menu-highlight;
	z-index: 99;
	font-size: 0;

	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		background: none;
	}
}

#twitter-head {
	display: inline-block;
	margin: 0;
	@include box (16px, 14px);
	overflow: hidden;
	@include bg (url(/i/design/twitter-ic.png), contain);
	background-size: 16px 14px;
	background-position: center;

	&:hover {
		opacity: .6;
	}


}

#facebook-head {
	display: inline-block;
	margin: 0 0 0 18px;
	@include box (8px, 15px);
	overflow: hidden;
	@include bg (url(/i/design/facebook-ic.png), contain);
	background-size: 8px 15px;
	background-position: center;

	&:hover {
		opacity: .6;
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

.translate {
	display: inline-block;
	margin: 0 18px;
	@include box (17px);
	overflow: hidden;
	@include bg (url(/i/design/translate-ic.png), contain);
	background-size: 17px 17px;
	background-position: center;
	transition: .4s;
	z-index: 99;

	&:hover {
		opacity: .6;
	}
}

#google_translate_element {
	opacity: 0;
}

.goog-te-gadget .goog-te-combo {
	margin: -1px 0 !important;
	padding: 5px 0 !important;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	display: block;
	@include box(17px, 18px);
	@include bg(url(/i/design/search-ic.png), contain);
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
	background-size: 17px 18px;
	background-position: center;

	&:hover {
		opacity: .6;
	}
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-top: 1px solid rgba(255, 255, 255, .8);
	border-bottom: 2px solid rgba(255, 255, 255, .5);
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

//$search-highlight-background-color: lightblue;
//$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}


// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

.body_6163 #mainholder {
	padding: 0;
	margin: 0;
}

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}


// SECTION GLOBAL STYLES
.sec-title {
	margin: 0;
	@include font3 (#fff, 48px, 500);
	line-height: 1.1;
}

.sec-button {
	display: inline-block;
	margin-top: 30px;
	width: 100%;
	min-height: 70px;
	padding: 22px 10px 20px;
	@include font3 (#11165A, 18px, 500);
	text-align: center;
	text-transform: uppercase;
	border-radius: 10px;

	&:hover {
		box-shadow: inset 0 0 0 50px $btn-hvr;
		color: #fff;
	}
}

.pattern {
	display: block;
	width: 100%;
	height: 8px;
	background: url(/i/design/pattern.jpg);
}




// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 30px;
	min-height: 350px;
	text-align: left;
	box-sizing: border-box;
	background: rgba(255, 255, 255, .9);

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {}

	h2 {}
}

//Content 2
.Content2Column {
	width: 70%;
	margin: 0;
	padding: 30px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	box-sizing: border-box;
	background: rgba(255, 255, 255, .9);

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {}

	h2 {}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 25%;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: left;
	box-sizing: border-box;
}

.ColumnRight {
	width: 25%;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// SCHOOL FEEDS
// -----------------------------------//


// NEWS SECTION
#news-diary-sec {
	padding: 85px 0;
	position: relative;
	overflow: hidden;

	&:before {
		@include before(100%, 100%);
		@include bg(url(/i/design/news-diary-bg.jpg), cover);
		@include abcent;
	}

	.wrapper {
		z-index: 10;
	}
}

// Social Feed
#social-sec {
	float: left;
	width: calc(50% - 15px);
	max-width: 480px;
	text-align: center;
	position: relative;

	h2.sec-title {
		text-align: right;
		transform: rotate(-90deg);
		position: absolute;
		top: 92px;
		left: -164px;
	}

	#social-box {
		padding: 10px;
		height: 460px;
		background: #fff;
		border-radius: 10px;

		#social-feed {
			width: 100%;
			max-width: 100%;
			max-height: 100%;
			border-radius: 10px;
			overflow-y: auto;
		}
	}

	a.sec-button {
		box-shadow: inset 0 0 0 2px $btn-bg;
		color: #fff;

		&:hover {
			box-shadow: inset 0 0 0 2px $btn-hvr, inset 0 0 0 50px $btn-hvr;
		}
	}
}

/* width */
#social-feed::-webkit-scrollbar {
	width: 10px;
}

/* Track */
#social-feed::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #cfd6d3;
}

/* Handle */
#social-feed::-webkit-scrollbar-thumb {
	background: #b0bbb6;
	border-radius: 10px;
}

/* Handle on hover */
#social-feed::-webkit-scrollbar-thumb:hover {
	background: $scroll-hvr;
}

p#ienotsupportedmessage {
	display: none;
}

//Diary
#SideHeadingDiary {
	float: right;
	width: calc(50% - 15px);
	max-width: 480px;

	h2.sec-title {
		text-align: left;
		transform: rotate(90deg);
		position: absolute;
		top: 70px;
		right: -144px;
	}

	ul.SideList {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		li {
			margin: 0 0 30px;
			width: 49%;
			max-width: 225px;

			&:nth-child(3),
			&:nth-child(4) {
				margin-bottom: 0;
			}

			a {
				width: 100%;
				height: 100%;
				background: $date-box-bg;
				border-radius: 10px;

				&:hover {
					background: #fff;

					p.date {
						background: $date-box-bg;
					}

					p.title {
						color: $date-box-bg;
					}
				}
			}

			p.date {
				float: none;
				padding: 28px 10px 0;
				min-height: 100px;
				@include font3 (#fff, 36px, 500);
				text-align: center;
				text-transform: uppercase;
				background: $date-bg;
				border-radius: 10px;

				span {
					display: inline-block;
				}
			}

			p.title {
				padding: 19px 10px;
				min-height: 115px;
				@include font (#fff, 18px);
				text-align: center;
				line-height: 1.4;
			}
		}
	}

	a.sec-button {
		float: left;
		max-width: calc(50% - 15px);
		background: $btn-bg;

		&:nth-of-type(2) {
			float: right;
		}
	}
}

//News
#SideHeadingNewsletters {
	float: left;
	min-height: 300px;
	width: calc(50% - 15px);
	max-width: 480px;
	text-align: center;
	position: relative;

	h2 {
		margin: 0;
		@include font3 (#fff, 48px, 500);
		line-height: 1.1;
		text-align: right;
		transform: rotate(-90deg);
		position: absolute;
		top: 148px;
		left: -224px;
	}

	ul.SideList {
		li {
			margin: 0 0 40px 0;

			img.newsletters-ic {
				float: left;
				margin: 0 30px 0 0;
				transition: .3s;
			}

			p.title {
				float: left;
				width: calc(100% - 120px);
				color: #fff;
				font-size: 18px;
				padding: 12px 20px 0 0;

				span {
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}

			&:hover {
				.newsletters-ic {
					transform: translateY(-10px);
					filter: invert(80%);
				}
			}
		}
	}


	a.more {
		box-shadow: inset 0 0 0 2px #e9a126;
		display: inline-block;
		margin-top: 3px;
		width: 100%;
		min-height: 70px;
		padding: 22px 10px 20px;
		@include font3 (#fff, 18px, 500);
		text-align: center;
		text-transform: uppercase;
		border-radius: 10px;

		&:hover {
			box-shadow: inset 0 0 0 50px $btn-hvr;
		}

		&:hover {
			box-shadow: inset 0 0 0 2px $btn-hvr;
		}
	}
}


//Related pages
#SideHeadingPages {
	overflow: hidden;
	background: $related-pages-bg;
	border-radius: 10px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: $related-pages-h2;
		text-align: center;
	}

	ul.SideList {
		padding: 0 0 30px 0;
		;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					text-indent: 5px;
					background: $related-pages-hvr;
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 13px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	background: $footer-bg;
	padding: 44px 0 40px;
}

footer>.wrapper {
	color: $footer-colour;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	text-transform: uppercase;
	line-height: 1.2;
}

#contact {
	float: left;
}

#register {
	float: right;
	color: $footer-register;
	text-align: right;

	p {
		margin: 0;
	}
}

.ofsted-sec {
	position: relative;
	display: block;
	padding: 15px 0 0 0;
	margin: 20px 0 0 0;
	border-top: 1px solid #D3D3D3;

	#ofsted-btn {
		margin: 0 auto;
		display: block;
		width: 120px;
		height: 120px;

		&:hover {
			transform: scale(1.03);
		}
	}
}

p.address {
	position: relative;
	float: left;
	margin: 0;
	padding: 0 0 0 34px;

	&:before {
		@include before(13px, 17px);
		@include bg(url(/i/design/address-ic.png), contain);
		top: 0;
		left: 0;
	}

	span {
		font-weight: 800;
	}
}

p.telephone {
	position: relative;
	float: left;
	clear: left;
	margin: 0;
	padding: 0 0 0 34px;

	&:before {
		@include before(17px, 18px);
		@include bg(url(/i/design/telephone-ic.png), contain);
		@include abcentv;
		left: 0;
	}
}

footer a[href^="mailto:"] {
	position: relative;
	float: left;
	clear: left;
	color: $footer-link;
	margin: 0;
	padding: 0 0 0 34px;

	&:before {
		@include before(16px, 12px);
		@include bg(url(/i/design/mail-ic.png), contain);
		@include abcentv;
		left: 0;
	}

	&:hover {
		color: $footer-link-hvr;
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	padding: 7px 0;
	background: $subfooter-bg;
	overflow: hidden;

	p {
		font-size: 14px;
		font-weight: 500;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: $subfooter-link;

		&:hover {
			text-decoration: underline;
		}
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

//$table-highlight-color: darkblue;
//$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
//$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote {
	border-radius: 10px !important;
}

blockquote p {
	text-align: center;
	font-weight: 600;
}

//$blockquote-background-color: #000;
//$blockquote-border-color: transparent;
//$blockquote-color: #fff;
//$blockquote-speech-mark-color: #fff;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}

// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}